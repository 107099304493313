import React, { useState, useEffect, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { ethers } from 'ethers';
import nftAbi from '../ABIs/NFTLaunchpadOnlyENOABI.json';
import './NFTPurchaseCard.css';
import ENOCoin from '../assets/ENOPrice.webp';

const NFTPurchaseCard = ({ nft, onGetNFTClick }) => {
  const [priceUsdt, setPriceUsdt] = useState('');
  const [totalMinted, setTotalMinted] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [saleStartTime, setSaleStartTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState({});
  const toast = useToast();

  const initializeProvider = () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      return new ethers.providers.Web3Provider(window.ethereum);
    }
    return null;
  };

  const provider = initializeProvider();

  const fetchMintedAndMaxSupply = useCallback(async () => {
    if (!provider) return;

    try {
      const nftContract = new ethers.Contract(nft.contractAddress, nftAbi, provider);
      const totalMintedBigNumber = await nftContract.totalSupply();
      const maxSupplyNumber = await nftContract.max_supply();
      const saleStartTimeNumber = await nftContract.saleStartTime();
      const nftPriceInENOBigNumber = await nftContract.NFTPriceInENO();

      console.log("NFT Price in ENO:", nftPriceInENOBigNumber);

      setTotalMinted(totalMintedBigNumber.toNumber());
      setMaxSupply(maxSupplyNumber.toNumber());
      setSaleStartTime(saleStartTimeNumber.toNumber());
      setPriceUsdt(ethers.utils.formatUnits(nftPriceInENOBigNumber, 18)); // Convertir el BigNumber a string con 18 decimales

      const currentTime = Math.floor(Date.now() / 1000);
      if (saleStartTimeNumber.toNumber() > currentTime) {
        setTimeLeft(calculateTimeLeft(saleStartTimeNumber.toNumber()));
      }
    } catch (error) {
      console.error('Error fetching minted and max supply:', error);
    }
  }, [nft.contractAddress, provider]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchMintedAndMaxSupply();
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [fetchMintedAndMaxSupply]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (saleStartTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (saleStartTime > currentTime) {
          setTimeLeft(calculateTimeLeft(saleStartTime));
        } else {
          setTimeLeft({});
        }
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [saleStartTime]);

  const calculateTimeLeft = (startTime) => {
    const now = Math.floor(Date.now() / 1000);
    const difference = startTime - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (60 * 60 * 24)),
        hours: Math.floor((difference % (60 * 60 * 24)) / (60 * 60)),
        minutes: Math.floor((difference % (60 * 60)) / 60),
        seconds: Math.floor(difference % 60),
      };
    } else {
      return {};
    }
  };


  if (!provider) {
    return <div>Please install MetaMask to view this content.</div>;
  }

  return (
    <div className="nft-purchase-card">
      <div className={`nft-video-container ${Object.keys(timeLeft).length !== 0 ? 'blur' : ''}`}>
        <video
          src={nft.video}
          alt="NFT Video"
          autoPlay
          muted
          loop
          playsInline
        />
        {Object.keys(timeLeft).length !== 0 && (
          <div className="countdown-overlay">
            <p className='countdown-NFT'>COUNTDOWN</p>
            <div className="countdown-timer">
              {timeLeft.days} &nbsp;&nbsp; {timeLeft.hours} &nbsp;&nbsp;  {timeLeft.minutes} &nbsp;&nbsp;  {timeLeft.seconds}
            </div>
            <div className="countdown-timer2">
              days  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
              hours &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
              minuts&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; seconds            
            </div>
          </div>
        )}
      </div>
      <div className='purchase__container'>
        <p className='purchase__title'>{nft.title}</p>
        <div>
          <div className='purchase__details-main'>
          <p className='purchase__description'>{nft.descriptionShort}</p>
            <div className='purchase__container-details'>            
              <div className='purchase__left-details'>
                <img src={ENOCoin} alt="Eno Price" className='purchase__image' />
                <span className='purchase__eno-price'>{parseFloat(priceUsdt).toFixed(2)} ENO</span>
              </div>        
              <div className='purchase__right-details'>
                <p className='purchase__minted-details'>{totalMinted} of {maxSupply} Editions Released</p>
              </div>            
            </div>
          </div>
        </div>
      </div>
      {Object.keys(timeLeft).length === 0 ? (
        <button className='hero__btn-mint color-1' onClick={onGetNFTClick}>
         Mint Now
        </button>
      ) : (
        <button className='hero__btn-mint color-1 disabled' disabled>
          Coming Soon
        </button>
      )}
    </div>
  );
};

export default NFTPurchaseCard;
