import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";

import ContractABI from "../ABIs/SenderETHABI.json";
import ContractABI2 from "../ABIs/SenderABI.json";
import ERC20ABI from "../ABIs/ERC20ABI.json"
import logotipo from "../assets/ENOLogo.svg";

import { useMetaMaskConnection } from "./MetaMaskConnection";
import './BridgeComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faXTwitter, faInstagram, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { useToast } from "@chakra-ui/react";


import './BridgeComponent.css';

function BridgeComponent() {
  const { provider, account, connectWalletHandler } = useMetaMaskConnection();
  const [minEth, setMinEth] = useState();
  const [tokenAmount, setTokenAmount] = useState('');
  const [senderAddress, setSenderAddress] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [destinationChainSelector, setDestinationChainSelector] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [originChain, setOriginChain] = useState();
  const [destinationChain, setDestinationChain] = useState();
  const [currentChainId, setCurrentChainId] = useState('0');
  const [transactionDetails, setTransactionDetails] = useState(null);
  const toast = useToast();
  const [userTokenBalance, setUserTokenBalance] = useState('');

  const chains = [
    { id: "0", name: "Ethereum" },
    { id: "3", name: "Arbitrum" },
  ];

  useEffect(() => {
    const getChainId = async () => {
      if (provider) {
        const network = await provider.getNetwork();
        setCurrentChainId(network.chainId.toString());
        switch (network.chainId) {
          case 1: // Ethereum
            setOriginChain("0");
            setDestinationChain("3"); // Por ejemplo, BSC Testnet por defecto
            setSenderAddress("0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F"); // Contrato Sender de Ethereum
            setTokenAddress("0x1C3d163219Bb74f430411b95D66b72056f366eC1"); // Token ENO de Ethereum
            break;
          /*           case 97: // BSC Testnet
                      setOriginChain("1");
                      setDestinationChain("0"); // Por ejemplo, Ethereum por defecto
                      setSenderAddress("");
                      setTokenAddress("");
                      break;
                    case 80001: // Mumbai
                      setOriginChain("2");
                      setDestinationChain("0"); // Por ejemplo, Ethereum por defecto
                      setSenderAddress("");
                      setTokenAddress(""); */
          case 42161: // Arbitrum
            setOriginChain("3");
            setDestinationChain("0"); // Por ejemplo, Ethereum por defecto
            setSenderAddress("0x521EF110851F00795b5840Ab56eDc2AF0342f16f");
            setTokenAddress("0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F"); // Token ENO de Arbitrum 

            break;
          default:
            setOriginChain("");
            setDestinationChain("");
        }
      }
    };
    getChainId();
  }, [provider]);

  useEffect(() => {
    const updateDestinationSettings = (selectedDestinationChain) => {
      switch (selectedDestinationChain) {
        case "0": // Configuracion Ethereum
          setDestinationChainSelector("5009297550715157269"); // Selector para Ethereum
          setReceiverAddress("0x44E549BBd6391C085795AAc0FbB83838f478EB6e"); // Receiver Address de Ethereum
          break;
        /*         case "1": // Configuracion BSC Testnet
                  setDestinationChainSelector("13264668187771770619"); // Selector para BSC Testnet
                  setReceiverAddress("0xAD49419D5086A44e7D65c8be02C2D9013cCEF5a5"); // Receiver Address de BSC Testnet
                  break;
                case "2": // Configuracion Mumbai
                  setDestinationChainSelector("12532609583862916517"); // Selector para Mumbai
                  setReceiverAddress("0xCcAd46Bd0EA63E246B2Da0317D64C74425A43327"); // Receiver Address de Mumbai
                  break; */
        case "3": // Configuracion Arbitrum Testnet
          setDestinationChainSelector("4949039107694359620"); // Selector para Arbitrum
          setReceiverAddress("0x44E549BBd6391C085795AAc0FbB83838f478EB6e"); // Receiver Address de Arbitrum
          break;

        default:
          setDestinationChainSelector("");
          setReceiverAddress("");
      }
    };
    updateDestinationSettings(destinationChain);
  }, [destinationChain]);

  // Filtra las opciones de las cadenas en función de la red conectada
  const filteredChainsForOrigin = chains.filter(chain => {
    if (currentChainId === '1' && chain.name === 'Ethereum') return true; // Ethereum
    /*  if (currentChainId === '97' && chain.name === 'BSC Testnet') return true; // BSC Testnet
     if (currentChainId === '80001' && chain.name === 'Mumbai') return true; // Mumbai */
    if (currentChainId === '42161' && chain.name === 'Arbitrum') return true; // Arbitrum */
    return false;
  });
  const filteredChainsForDestination = chains.filter(chain => chain.id !== originChain);

  const getMinEthRequired = useCallback(async () => {
    if (provider && senderAddress) {
      const abi = currentChainId !== '1' ? ContractABI2 : ContractABI;
      const bridgeContract = new ethers.Contract(
        senderAddress,
        abi,
        provider.getSigner()
      );
      const minEthRequired = await bridgeContract.minEthRequired();
      setMinEth(ethers.utils.formatEther(minEthRequired));
    }
  }, [provider, senderAddress, currentChainId]);

  const lockTokensAndEth = async () => {
    if (provider && senderAddress) {
      const abi = currentChainId !== '1' ? ContractABI2 : ContractABI;
      const bridgeContract = new ethers.Contract(
        senderAddress,
        abi,
        provider.getSigner()
      );
      try {
        await checkTokenBalanceAndAllowance();

        await approveTokens();
        console.log("Tokens approved for bridging");
        console.log("destinationChain:", destinationChain);
        console.log("destinationChainSelector:", destinationChainSelector);
        console.log("receiverAddress:", receiverAddress);
        console.log("account:", account);
        console.log("tokenAmount:", tokenAmount);
        console.log("minEth:", minEth);

        if (destinationChain === "0") {
          console.log("Calling unlockWithMinEth");
          const tx = await bridgeContract.unlockWithMinEth(
            destinationChainSelector,
            receiverAddress,
            account,
            ethers.utils.parseUnits(tokenAmount, "ether"),
            0, // payFeesIn (0 para Native, 1 para LINK)
            { value: ethers.utils.parseUnits(minEth, "ether") }
          );
          await tx.wait();
          toast({
            title: 'Tokens bridged',
            description: 'Tokens bridged successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          console.log("Tokens bridged");
          console.log(tx);
          setTransactionDetails(tx);
        } else {
          console.log("Calling mintWithMinEth");
          const tx = await bridgeContract.mintWithMinEth(
            destinationChainSelector,
            receiverAddress,
            account,
            ethers.utils.parseUnits(tokenAmount, "ether"),
            0, // payFeesIn (0 para Native, 1 para LINK)
            { value: ethers.utils.parseUnits(minEth, "ether") }
          );
          await tx.wait();
          toast({
            title: 'Tokens bridged',
            description: 'Tokens bridged successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          console.log("Tokens bridged");
          console.log(tx);
          setTransactionDetails(tx);
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        console.error("Error bridged tokens:", error);
      }
    }
  };

  const checkTokenBalanceAndAllowance = async () => {
    const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider.getSigner());
    const balance = await tokenContract.balanceOf(account);
    const allowance = await tokenContract.allowance(account, senderAddress);
    const amountToTransfer = ethers.utils.parseUnits(tokenAmount, "ether");

    console.log(`Token balance is: ${ethers.utils.formatEther(balance)} tokens`);
    console.log(`Current allowance is: ${ethers.utils.formatEther(allowance)} tokens`);
    console.log(`Attempted transfer amount is: ${ethers.utils.formatEther(amountToTransfer)} tokens`);

    if (balance.lt(amountToTransfer)) {
      toast({
        title: 'ERROR',
        description: 'Transfer amount exceeds your token balance.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      throw new Error("Insufficient token balance for transfer.");
    }

    if (allowance.lt(amountToTransfer)) {
      console.log("Insufficient allowance for transfer. Approving tokens...");
      await approveTokens();
    } else {
      console.log("Sufficient allowance for transfer. No need to approve more tokens.");
    }
  };

  const approveTokens = async () => {
    try {
      const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider.getSigner());
      const currentAllowance = await tokenContract.allowance(account, senderAddress);
      const amountToApprove = ethers.utils.parseUnits(tokenAmount, "ether");

      if (currentAllowance.lt(amountToApprove)) {
        console.log(`Current allowance is ${ethers.utils.formatEther(currentAllowance)}. Approving more tokens...`);
        const tx = await tokenContract.approve(senderAddress, amountToApprove);
        await tx.wait();
        console.log("Tokens approved for transfer.");
      } else {
        console.log("Sufficient allowance already granted. No need to approve more tokens.");
      }
    } catch (error) {
      toast({
        title: 'ERROR',
        description: 'Transfer amount exceeds your token balance.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      console.error("Error approving tokens:", error);
      // Asegúrate de manejar este error de manera que no interrumpa el flujo de tu aplicación.
      // Por ejemplo, podrías decidir lanzar el error o manejarlo de manera específica.
      throw error;
    }
  };

  useEffect(() => {
    getMinEthRequired();
  }, [getMinEthRequired]);

  const formatAccount = (account) => {
    // Asegúrate de que la cuenta es una cadena y tiene la longitud esperada
    if (typeof account === 'string' && account.length > 8) {
      const firstPart = account.substring(0, 4);
      const lastPart = account.substring(account.length - 4);
      return `${firstPart}...${lastPart}`;
    }
    return account;
  };
  // Función para obtener el balance de tokens del usuario
  const getUserTokenBalance = useCallback(async () => {
    if (account && tokenAddress && provider) {
      const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider);
      const balance = await tokenContract.balanceOf(account);
      const formattedBalance = ethers.utils.formatUnits(balance, 'ether'); // Ajustar según los decimales del token si no son 18
      setUserTokenBalance(formattedBalance);
    }
  }, [account, tokenAddress, provider]);

  // Función para establecer el balance máximo en los inputs
  const setMaxTokenAmount = () => {
    setTokenAmount(userTokenBalance);
  };

  useEffect(() => {
    getUserTokenBalance();
  }, [getUserTokenBalance]);

  // Ahora, basándonos en el `currentChainId`, decidimos qué renderizar
  if (currentChainId !== '1' || !account) { // '1' es el chainId para Ethereum Mainnet
    return (
      <div className="network-warning">
        <button className="bridge__btn-bridge color-1" onClick={connectWalletHandler}>Connect Wallet</button>
        <p>Please switch to the Ethereum network <br />in order to use the bridge.</p>
      </div>
    );
  } else {

    return (
      <>
        <header className="app__header">
          <div className="left__menu">
            <a href="https://enotoken.io" target="_blank">
              {/* <img src={logoImage} alt="Logo de la Empresa" className="company__logo" /> */}
            </a>
          </div>
          {/* <div className='menu__option'>
            <div className="menu__selection1">Transfer</div>
            <div className="menu__selection2">FAQ</div>
          </div> */}
        </header>


        <div className="containerBridge">
          <div className="titleBridge"><h2>ENO Bridge</h2></div>
          <div className="mainContainer">

            <div className="sourceChainContainer" id="sourceChainContainer">
              <div className="horizontal-layout">
                <label htmlFor="sourceChain" className="contentLabel">Source Chain</label>
                <select
                  value={originChain}
                  onChange={(e) => setOriginChain(e.target.value)}
                  className="selectBridge">
                  {filteredChainsForOrigin.map(chain => (
                    <option key={chain.id} value={chain.id}>{chain.name}</option>
                  ))}
                </select>
              </div>
              <div className="bridgeForm">
                <div className="inputContainer">
                  <input
                    type="text"
                    value={tokenAmount}
                    onChange={(e) => setTokenAmount(e.target.value)}
                    placeholder="Enter amount"
                    className="inputAmount"
                  />

                  <button className="max" onClick={setMaxTokenAmount}>MAX</button>
                </div>
              </div>
              <div className="balance-max-container">
                <p>{userTokenBalance} ENO Tokens</p>
              </div>
            </div>

            <div className="destinationChainContainer" id="destinationChainContainer">
              <div className="horizontal-layout">
                <label htmlFor="destinationChain" className="contentLabel">Destination Chain</label>
                <select
                  value={destinationChain}
                  onChange={(e) => setDestinationChain(e.target.value)}
                  className="selectBridge">
                  {filteredChainsForDestination.map(chain => (
                    <option key={chain.id} value={chain.id}>{chain.name}</option>
                  ))}
                </select>
              </div>
              <div className="bridgeForm">
                <div className="inputDestination">
                  <input
                    type="text"
                    value={tokenAmount}
                    onChange={(e) => setTokenAmount(e.target.value)}
                    placeholder="Enter amount"
                    className="inputAmount"
                  />
                </div>
              </div>
              <div className="balance-max-container">
                <p>{userTokenBalance} ENO Tokens</p>
                {/* <button className="max" onClick={setMaxTokenAmount}>Max</button> */}
              </div>
            </div>
          </div>
          <button onClick={lockTokensAndEth} className="bridge__btn color-1">Bridge ENO Tokens</button>
          {transactionDetails && (
            <div>
              <p className="white">Bridging Tokens: <a href={`https://ccip.chain.link/tx/${transactionDetails.hash}`} target="_blank" rel="noopener noreferrer">(Click here to view bridge transaction status)</a></p>
              <p className="white">Add the Arbitrum ENO token to your Wallet:</p>
              <p className="white">0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F</p>
              <p className="white">Clic here to see the guide</p>
            </div>
          )}

          {/* <div className='global__icons'>
            <div className='social__container'>
              <div className="follow__text">Follow Us</div>
              <div className="social__icons__container">
                <div className="social__icons icon__list">
                  <a href="https://www.youtube.com/channel/UClFLsvU78zRxuI-q4_WT4-g/videos" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a href="https://www.facebook.com/ENOtoken" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href="https://mx.linkedin.com/company/enotoken" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                  <a href="https://twitter.com/Enotoken" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </a>
                  <a href="https://www.instagram.com/enotoken/?utm_medium=copy_link" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href="https://discord.com/invite/3eZewHzJU5" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faDiscord} />
                  </a>
                  <a href="https://t.me/EnoToken" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegram} />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>

    );
  }
}
export default BridgeComponent;