import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, useToast, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ethers } from "ethers";
import mintBadgeParisABI from "../ABIs/newBadgeENOABI.json";
import Loader from "../assets/LoadingMachine.mp4";
import badgesNFT from '../assets/BackBadges.mp4';
import badgeBlanco from "../assets/badgesBlanco.mp4";
import "./BadgeDetailView.css";

import LatestPost from "../Templates/LatestPosts";
import Newsletter from "../Templates/Newsletter";
import EnoBadges from "../Templates/BadgesNewsletter";
import Accordion from "../Templates/Accordion";

const BadgeDetailView = ({
  setHeaderVisible,
  setFooterVisible,
  setNavBarVisible,
}) => {
  const { badgeId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [badgeDetails, setBadgeDetails] = useState(null);
  const [totalMinted, setTotalMinted] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMinted, setHasMinted] = useState(false);

  useEffect(() => {
    if (
      typeof setHeaderVisible !== 'function' ||
      typeof setFooterVisible !== 'function' ||
      typeof setNavBarVisible !== 'function'
    ) {
      console.error('One or more of the functions are not passed correctly');
      return;
    }

    if (window.ethereum && window.ethereum.isMetaMask) {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(web3Provider);
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          const userSigner = web3Provider.getSigner();
          setSigner(userSigner);
          checkIfMinted(userSigner, accounts[0]);
        })
        .catch((error) => {
          console.error("User denied account access", error);
          toast({
            title: "Account access denied",
            description: "You need to grant account access to use this feature.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } else {
      console.error("MetaMask is not installed");
      toast({
        title: "MetaMask not installed",
        description: "Please install MetaMask to use this feature.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast, setHeaderVisible, setFooterVisible, setNavBarVisible]);

  useEffect(() => {
    const fetchBadgeDetails = async () => {
      if (!provider) return;
      try {
        const badgeContract = new ethers.Contract(
          badgeId,
          mintBadgeParisABI,
          provider
        );
        const tokenURI = await badgeContract.tokenURI(1);
        const response = await fetch(tokenURI);
        const metadata = await response.json();
        console.log("Loaded badge data:", metadata);

        setBadgeDetails({
          name: metadata.name,
          image: metadata.image,
          contractAddress: badgeId,
          description: metadata.description,
          descriptionLong: metadata.descriptionLong || metadata.description,
        });

        fetchMintedAndMaxSupply(badgeId);
      } catch (error) {
        console.error("Error loading badge data:", error);
        toast({
          title: "Error loading badge data",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchBadgeDetails();
  }, [badgeId, provider, toast]);

  const fetchMintedAndMaxSupply = useCallback(
    async (contractAddress) => {
      if (!provider) return;
      const badgeContract = new ethers.Contract(
        contractAddress,
        mintBadgeParisABI,
        provider
      );
      try {
        const totalMintedBigNumber = await badgeContract.totalSupply();
        const maxSupplyNumber = await badgeContract.MAX_SUPPLY();
        setTotalMinted(totalMintedBigNumber.toNumber());
        setMaxSupply(maxSupplyNumber.toNumber());
      } catch (error) {
        console.error("Error fetching total supply or max supply:", error);
        toast({
          title: "Error",
          description: "Error fetching total supply or max supply.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [provider, toast]
  );

  const checkIfMinted = useCallback(
    async (signer, address) => {
      if (!provider || !badgeDetails) return;
      const badgeContract = new ethers.Contract(
        badgeDetails.contractAddress,
        mintBadgeParisABI,
        signer
      );
      try {
        const balance = await badgeContract.balanceOf(address);
        setHasMinted(balance.toNumber() > 0);
      } catch (error) {
        console.error("Error checking if already minted:", error);
      }
    },
    [provider, badgeDetails]
  );

  useEffect(() => {
    if (badgeDetails) {
      fetchMintedAndMaxSupply(badgeDetails.contractAddress);
      const intervalId = setInterval(() => {
        fetchMintedAndMaxSupply(badgeDetails.contractAddress);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [badgeDetails, fetchMintedAndMaxSupply]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 1024) {
        setHeaderVisible(false);
        setFooterVisible(false);
        setNavBarVisible(false);
      } else {
        setHeaderVisible(true);
        setFooterVisible(true);
        setNavBarVisible(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      setHeaderVisible(true);
      setFooterVisible(true);
      setNavBarVisible(true);
    };
  }, [setHeaderVisible, setFooterVisible, setNavBarVisible]);

  const validateContractAddresses = () => {
    if (!badgeDetails) {
      console.error("Badge details not available");
      toast({
        title: "Badge details not available",
        description: "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (!ethers.utils.isAddress(badgeDetails.contractAddress)) {
      console.error("Invalid contract address");
      toast({
        title: "Invalid contract address",
        description: "The contract address is invalid.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const buyWithENO = async () => {
    if (!signer || !badgeDetails || !validateContractAddresses() || hasMinted || totalMinted >= maxSupply) {
      toast({
        title: "Invalid Badge Details",
        description: "Fully claimed or you have already minted one.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const badgeContract = new ethers.Contract(
        badgeDetails.contractAddress,
        mintBadgeParisABI,
        signer
      );

      const address = await signer.getAddress();
      const buyTx = await badgeContract.mint(address);
      await buyTx.wait();

      toast({
        title: "Purchase Successful",
        description: "You have successfully purchased the Badge with ENO.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/my-badges");
    } catch (error) {
      if (error.code === 4001) {
        toast({
          title: "Transaction Denied",
          description: "You denied the transaction signature.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (error.message.includes("insufficient funds")) {
        toast({
          title: "Insufficient Funds",
          description: "Not enough ENO for gas fees. Please ensure your wallet has sufficient funds.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (error.message.includes("Each address may only mint one NFT")) {
        toast({
          title: "Minting Error",
          description: "You have already minted 1 Badge. Please check in the 'My Badges' tab.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (error.message.includes("Sale has not started yet")) {
        toast({
          title: "Purchase Failed",
          description: "Sale has not started yet.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Purchase Failed",
          description: `An error occurred during the transaction: ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  if (!badgeDetails) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {loading && (
        <div className="containerBadge loader-overlay">
          <h2 className="hero__title">Loading...</h2>
              <Box display="flex" justifyContent="center" alignItems="center">
                <video src={Loader} autoPlay loop muted width="350px" height="200px"></video>
              </Box>
            </div>
           )}

          <div className={`badge-detail-container ${loading ? "blurred" : ""}`}>
         <a href="/mint-badges" className="back__button" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faChevronLeft} />
        </a>
        <div className="badge-detail-video">
          <img src={badgeDetails.image} alt={`${badgeDetails.name} image`} />
        </div>
        <div className="badge-detail-content">
          <div className="BADGE__line-container">
            <p className="BADGE__line"></p>
          </div>

          <div className="backButton__Container">
            <div className="backButton__left">
              <a href="/mint-badges" className="back__buttonDesktop" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faChevronLeft} />
              </a>
            </div>
            <div className="backButton__right-badge">
              <a href="/mint-badges">
                <h2>Back to Launchpad</h2>
              </a>
            </div>
          </div>

          <h2 className="BADGE__title">{badgeDetails.name}</h2>
          <div className="BADGE__description">
            <h2 className="about__badge">About Badge</h2>
            <p className="details__badge">{badgeDetails.descriptionLong}</p>
          </div>
          <div className="BADGE__ContainerBtn">
            <div className="BADGE__btnENO">
              <Button
                className="BADGE__btn color-1"
                colorScheme="teal"
                size="sm"
                onClick={buyWithENO}
                isDisabled={loading || totalMinted >= maxSupply}
              >
                {totalMinted >= maxSupply ? "Fully Claimed" : "Mint"}
              </Button>
            </div>
            <div className="NFT__Container-Editions">
              <p className="purchase__badge-released">
                {totalMinted} of {maxSupply} Editions Released
              </p>

              <p className="purchase__badge-mobile">
                {totalMinted} of {maxSupply} Editions Released
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`badge-detail-container-mobile ${loading ? "blurred" : ""}`}>
        <a href="/mint-badges" className="back__button" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faChevronLeft} />
        </a>
        <div className="badge-detail-video">
          <img src={badgeDetails.image} alt={`${badgeDetails.name} image`} />
        </div>
        <div className="badge-detail-content">
          <div className="BADGE__line-container">
            <p className="BADGE__line"></p>
          </div>

          <div className="backButton__Container">
            <div className="backButton__left">
              <a href="/mint-badges" className="back__buttonDesktop" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faChevronLeft} />
              </a>
            </div>
            <div className="backButton__right">
              <a href="/launchpad">
                <h2>Back to Launchpad</h2>
              </a>
            </div>
          </div>

          <h2 className="BADGE__title">{badgeDetails.name}</h2>
          <div className="BADGE__description">
            <div className="NFT__buttons-mobile">
              <div className="NFT__Container-Editions">
                <p className="purchase__minted-details-mobile">
                  {totalMinted} of {maxSupply} Editions Released
                </p>
              </div>
            </div>

            <h2 className="about__badge">About Badge</h2>
            <p className="details__badge">{badgeDetails.descriptionLong}</p>
          </div>

          <div className="BADGE__ContainerBtn">
            <div className="BADGE__btnENO">
              <Button
                className="BADGE__btn color-1"
                colorScheme="teal"
                size="sm"
                onClick={buyWithENO}
                isDisabled={loading || totalMinted >= maxSupply}
              >
                {totalMinted >= maxSupply ? "Fully Claimed" : "Mint"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* ======= What Are Eno Badges - Video ======= */}

      <div className="component__container">
        <EnoBadges showSection="badgesDetailView" />
        <section className="newspaper-BADGE">
          <h2 className="title-BADGE-mobile">What are ENO Badges?</h2>
          <video
            src={badgeBlanco}
            autoPlay
            loop
            muted
            playsInline
            className="layer BADGE-mobile"
          ></video>
          <p className="text-subtitle-BADGE-mobile">
            ENO‘s Badges are NFTs that verify your participation
            in an activity within our social ecosystem.
          </p>
          <a
            href="https://docs.enotoken.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="button__BADGE-mobile"
          >
            <button className="hero__btn color-1">Read More</button>
          </a>
        </section>

        <Accordion showSection="accordeon-4" />
        <LatestPost showSection="lastpost-1" />
        <div className="newsletter-badge__container">
          <Newsletter />
        </div>
      </div>
    </>
  );
};

export default BadgeDetailView;
