import React from 'react'
import backNFT from '../assets/BackDeskNFT.mp4';

function newspaperBadges() {
    return (
        <>
            <section className="EnoBadges">
                <div className="EnoBadges__left">
                    <h2 className="hero__title">What are ENO NFTs?</h2>
                    <p className="text__subtitle">Each NFT has a unique and interchangeable identity, making them perfect for certifying the authenticity and ownership of digital collectibles, art, and other unique items.</p>
                    {/* <a
                        href="https://docs.enotoken.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className='button__NFT'>
                        <button className="hero__btn-alternate color-1">
                            Read More
                        </button>
                    </a> */}
                </div>
                <div className="EnoBadges__right">
                    <div className='backBadges'>
                        <video
                            src={backNFT}
                            autoPlay
                            loop
                            muted
                            playsInline
                            className='layer'>
                        </video>
                    </div>
                </div>
            </section>
        </>
    )
}

export default newspaperBadges