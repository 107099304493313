import React, { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useNetworkSwitcher, chain } from '../hooks/useNetworkSwitcher';
import useMetaMaskConnector from '../hooks/useMetaMaskConnector';
import { useToast, Flex } from '@chakra-ui/react';
import BadgeMintCard from './BadgeMintCard';
import { Link, useNavigate } from 'react-router-dom';
import './MintBadge.css';
import contractABI from '../ABIs/newBadgeENOABI.json';
import EnoBadges from "../Templates/BadgesNewsletter";

import LatestPost from "../Templates/LatestPosts";
import Accordion from "../Templates/Accordion";
import Newsletter from "../Templates/Newsletter";

function MintBadge() {
  const { currentNetwork, changeNetwork, error } = useNetworkSwitcher();
  const { isConnected, connectMetaMask, message } = useMetaMaskConnector();
  const toast = useToast();
  const navigate = useNavigate();
  const [badges, setBadges] = useState([]);
  const [badgeSupplyData, setBadgeSupplyData] = useState({});

  useEffect(() => {
    const fetchBadgeData = async () => {
      const contractAddresses = [
        '0xCc83632FbEd7c1580f6fcF07E38423b70c527BC9',
        '0x2A06B2c0999Af12C251c55D6E2c67330AeAb3C86',
        '0xD6C9365273539C7722EAb3BAC3D76dD3b23e6Ff3',
        '0x8cDff0DF63C816df0d1BbeC7f9e7771915311EDf',
        '0x3B70F7347Ed816CDE7A5B25c5AA9BdDE753e3966',
        '0x281d59301C137E25150139da5BE433D15e8e732F',
        '0xa1b79845a7a704D0877C8a4A80072F8ce422104b',
      ];

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const badgeDataPromises = contractAddresses.map(async (address) => {
        const contract = new ethers.Contract(address, contractABI, provider);
        const tokenURI = await contract.tokenURI(1); // Asumimos que el token con ID 1 tiene los metadatos que necesitamos

        const response = await fetch(tokenURI);
        const metadata = await response.json();

        console.log('Fetched metadata for contract:', address, metadata);

        return {
          name: metadata.name,
          image: metadata.image,
          contractAddress: address,
          description: metadata.description,
        };
      });

      try {
        const badgesMainnet = await Promise.all(badgeDataPromises);
        setBadges(badgesMainnet);
      } catch (error) {
        console.error('Error fetching badge data:', error);
      }
    };

    fetchBadgeData();
  }, []);

  useEffect(() => {
    const fetchBadgeSupplyData = async () => {
      if (!badges.length) return;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const data = {};
      for (const badge of badges) {
        try {
          const contract = new ethers.Contract(badge.contractAddress, contractABI, provider);
          const tokenId = await contract._tokenId();
          const maxSupply = await contract.MAX_SUPPLY();
          data[badge.contractAddress] = {
            totalSupply: tokenId.toNumber() - 1,
            maxSupply: maxSupply.toNumber(),
          };
        } catch (error) {
          console.error(`Error fetching data for badge ${badge.name}:`, error);
        }
      }
      setBadgeSupplyData(data);
    };

    fetchBadgeSupplyData();
    const interval = setInterval(fetchBadgeSupplyData, 30000);

    return () => clearInterval(interval);
  }, [badges]);

  const NFTPurchase = useCallback(async (contractAddress) => {
    if (!isConnected) {
      connectMetaMask();
    } else {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, contractABI, signer);
        const transaction = await nftContract.mint(await signer.getAddress());
        await transaction.wait();
        toast({
          title: 'Mint successful!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error(error);
        let errorMessage = 'Minting failed. Please try again.';
        if (error.code === 4001) {
          errorMessage = 'Transaction rejected by user. Please approve the transaction to mint your NFT.';
        } else if (error.message.includes('insufficient funds')) {
          errorMessage = 'Not enough ETH for gas fees. Please ensure your wallet has sufficient funds.';
        } else if (error.message.includes('Each address may only mint one NFT')) {
          errorMessage = 'You have already minted 1 Badge. Please check in the "My Badges" tab.';
        }
        toast({
          title: 'Minting Error',
          description: errorMessage,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }, [isConnected, connectMetaMask, toast]);

  const handleConnect = async () => {
    try {
      await connectMetaMask();
      await changeNetwork();
    } catch (error) {
      console.error('Failed to connect MetaMask and switch network:', error);
    }
  };

  const renderConnectMessage = () => {
    if (!isConnected && currentNetwork !== chain) {
      return 'Please connect your wallet and switch to the correct network.';
    } else if (!isConnected) {
      return 'Please connect your wallet.';
    } else if (currentNetwork !== chain) {
      return 'Please switch to the correct network.';
    }
    return '';
  };

  const renderConnectButtonLabel = () => {
    if (!isConnected) {
      return 'Connect Wallet';
    } else if (currentNetwork !== chain) {
      return 'Change Network';
    }
    return '';
  };

  const isMetaMaskInstalled = () => {
    return typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined';
  };

  if (!isMetaMaskInstalled()) {
    return (
      <div className="install-metamask-container">
        <button as="a" href="https://metamask.io/download.html" target="_blank" colorScheme="teal" size="lg" className='connect__container-install'>
          Install Wallet
        </button>
        <p className="install-message">Please install a web3 compatible wallet to proceed.</p>
      </div>
    );
  }

  if (!isConnected || currentNetwork !== chain) {
    return (
      <div className="connect-container">
        <button onClick={handleConnect} colorScheme="teal" size="lg" className='connect-container-btn'>
          {renderConnectButtonLabel()}
        </button>
        <p className="connect-message">{renderConnectMessage()}</p>
      </div>
    );
  }

  const handleMintNowClick = (badgeId) => {
    const formattedBadgeId = encodeURIComponent(badgeId).replace(/%20/g, '-');
    navigate(`/badge-detail/${formattedBadgeId}`);
  };

  return (
    <div className="container">
      <EnoBadges showSection="badges-alternative" />
      <Flex justifyContent="center" width="100%" alignItems="center">
        <Flex alignItems="center">
          <h2 className="hero__title">Mint Your NFT Badges</h2>
        </Flex>
      </Flex>

      <div className="launchpad-container">
        <div className="launchpad__container-btns">
          <Link to="/mint-badges">
            <button className="switch-button-active">Mint Badges</button>
          </Link>
          <Link to="/my-badges">
            <button className="switch-button">My Badges</button>
          </Link>
        </div>
      </div>

      <div className="nft-grid-list">
        {badges.map(badge => (
          <BadgeMintCard
            key={badge.contractAddress}
            badge={badge}
            badgeData={badgeSupplyData[badge.contractAddress] || { totalSupply: 0, maxSupply: 0 }}
            mintFunction={NFTPurchase}
            onMintNowClick={() => handleMintNowClick(badge.contractAddress)}
            className="grid-items"
          />
        ))}
      </div>
      <Accordion showSection="accordeon-3"/>
      <LatestPost showSection="lastpost-2"/>
      <Newsletter />
    </div>
  );
}

export default MintBadge;
