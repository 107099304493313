import React, { useState, useEffect } from 'react';
import { useToast, Button } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import NFTPurchaseCard from './NFTPurchaseCard';
import NewsNFTs from '../Templates/NewsNFTs';
import '../App.css';
import './NFTPurchase.css';
import './globalStyles.css';
import { useNetworkSwitcher, chain } from '../hooks/useNetworkSwitcher';
import useMetaMaskConnector from '../hooks/useMetaMaskConnector';

import Accordion from "../Templates/Accordion";
import LatestPostsWhite from '../Templates/LatestPostWhite';
import Newsletter from '../Templates/Newsletter';
import EnoBadges from "../Templates/BadgesNewsletter";

import Footer from "./Footer";

const NFTPurchase = () => {
  const toast = useToast();
  const [nfts, setNfts] = useState([]);
  const { currentNetwork, changeNetwork } = useNetworkSwitcher();
  const { isConnected, connectMetaMask, message } = useMetaMaskConnector();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNFTData = async () => {
      try {
        const response = await fetch('/nftData.json');
        const data = await response.json();
        const nftArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
        setNfts(nftArray);
      } catch (error) {
        console.error('Error fetching NFT data:', error);
        toast({
          title: 'Error',
          description: 'There was an error loading the NFT data.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchNFTData();
  }, [toast]);

  useEffect(() => {
    const header = document.querySelector('header');

    if (header) {
      header.style.backgroundColor = 'var(--black-eno-gray)';
    }

    return () => {
      if (header) {
        header.style.backgroundColor = ''; 
      }
    };
  }, []);

  const handleConnect = async () => {
    try {
      await connectMetaMask();
      await changeNetwork();
    } catch (error) {
      console.error('Failed to connect MetaMask and switch network:', error);
    }
  };

  const renderConnectMessage = () => {
    if (!isConnected && currentNetwork !== chain) {
      return 'Please connect your wallet and switch to the correct network.';
    } else if (!isConnected) {
      return 'Please connect your wallet.';
    } else if (currentNetwork !== chain) {
      return 'Please switch to the correct network.';
    }
    return '';
  };

  const renderConnectButtonLabel = () => {
    if (!isConnected) {
      return 'Connect Wallet';
    } else if (currentNetwork !== chain) {
      return 'Change Network';
    }
    return '';
  };

  const isMetaMaskInstalled = () => {
    return typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined';
  };

  if (!isMetaMaskInstalled()) {
    return (
      <div className="install-metamask-container">
        <button as="a" href="https://metamask.io/download.html" target="_blank" colorScheme="teal" size="lg" className='connect__container-install'>
          {renderConnectButtonLabel()}
        </button>
        <p className="install-message">Please install a web3 compatible wallet to proceed.</p>
      </div>
    );
  }

  if (!isConnected || currentNetwork !== chain) {
    return (
      <div className="connect-container">
        <Button onClick={handleConnect} colorScheme="teal" size="lg" className='connect-container-btn'>
          {renderConnectButtonLabel()}
        </Button>
        <p className="connect-message">{renderConnectMessage()}</p>
      </div>
    );
  }

  const handleGetNFTClick = (nftId) => {
    navigate(`/nft-detail/${nftId}`);
  };

  return (
    <div className="nft-purchase-container" style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <div className="container">
        <EnoBadges showSection="nfts" />
        <h2 className="hero__titleWhite">Discover our NFT Collection</h2>

        <div className="launchpad-container-white">
          <div className="launchpad__container-btns">
            <Link to="/launchpad">
              <button className="switch-button-active-white">Mint NFT</button>
            </Link>
            <Link to="/my-nft">
              <button className="switch-button-white">My NFTs</button>
            </Link>
          </div>
        </div>

        <div className="nft-grid-list">
          {nfts.map((nft) => (
            <NFTPurchaseCard
              key={nft.contractAddress}
              nft={nft}
              onGetNFTClick={() => handleGetNFTClick(nft.id)}
            />
          ))}
        </div>
        <Accordion showSection="accordeon-5" />
        <LatestPostsWhite showSection="lastpost-1"/>
        <Newsletter />
      </div>
    </div>
  );
};

export default NFTPurchase;
