import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconBadge } from "../assets/IconsNav/IconBadges.svg";
import { ReactComponent as IconBridge } from "../assets/IconsNav/IconBridge.svg";
import { ReactComponent as IconLaunchpad } from "../assets/IconsNav/IconLaunchpad.svg";
import { ReactComponent as IconStack } from "../assets/IconsNav/IconStack.svg";

const NavBar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <header className="NavBar" id="NavBar">
      <nav className="nav element">
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <a
                href="/mint-badges"
                className={`nav__link ${activeLink === '/mint-badges' ? 'active' : ''}`}
              >
                <IconBadge width="25px" height="25px" />
                <span className="nav__name">Badges</span>
              </a>
            </li>

            <li className="nav__item">
              <a
                href="/launchpad"
                className={`nav__link ${activeLink === '/launchpad' ? 'active' : ''}`}
              >
                <IconLaunchpad width="25px" height="25px" />
                <span className="nav__name">Launchpad</span>
              </a>
            </li>

            <li className="nav__item">
              <a
                href="/bridge"
                className={`nav__link ${activeLink === '/bridge' ? 'active' : ''}`}
              >
                <IconBridge width="25px" height="25px" />
                <span className="nav__name">Bridge</span>
              </a>
            </li>
            {/* <li className="nav__item">
              <a
                href="/stack"
                className={`nav__link ${activeLink === '/stack' ? 'active' : ''}`}
              >
                <IconStack width="32px" height="32px" />
                <span className="nav__name">Stack</span>
              </a>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
