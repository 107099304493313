import React, { useState } from 'react';
import './Accordion.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import NFTDetailView from '../components/NFTDetailView';

const FAQSAccordion = ({ showSection }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // MyBadge | accordion 3 | Blue light Desktop & Mobile
  // MintBadge | accordion 3 | Blue light Desktop & Mobile
  // BadgeDetailView | accordion 4 | Blue light Desktop - White Mobile

  // MyNFT | accordion 1 | Blue light Desktop & Mobile
  // NFTPurchase | accordion 1 | Blue light Desktop & Mobile
  // NFTDetailView | accordion 2 | Blue light Desktop - White Mobile

  return (
    <>
      {/* Blue light desktop & mobile */}
      {showSection === "accordeon-1" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText">
              <li className={activeIndex === 0 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question">What is ENO?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(0)}>
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>
              <li className={activeIndex === 1 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question">What is the ENO Launchpad?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(1)}>
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>The ENO Launchpad is an innovative platform designed to empower the Web3 community, creators, innovators, and project developers by providing tools for staking, launching new digital assets, and minting non-fungible tokens (NFTs). The Launchpad simplifies the process of adopting, managing, and distributing digital assets while ensuring high standards of quality and security.</p>
              </li>
              <li className={activeIndex === 2 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question">What are ENO NFT Badges?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(2)}>
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO Badges are digital medals that demonstrate your participation on the platform. Each badge represents your attendance and commitment to various activities and events sponsored by ENO. They are unique to you and protected by blockchain technology, meaning no one can take them away or counterfeit them.</p>
              </li>
              <li className={activeIndex === 3 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question">How can I mint an NFT?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(3)}>
                    <FontAwesomeIcon icon={activeIndex === 3 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. Select <strong>"Mint NFT".</strong><br />
                  3. Choose the <strong>NFT</strong> you want to claim and click the <strong>"Mint"</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT's"</strong> to see all the NFT's you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.enotoken.io/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
      {/* Blue light desktop & White mobile */}
      {showSection === "accordeon-2" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText-alt">

              <li className={activeIndex === 0 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question-alt">What is ENO?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(0)}>
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question-alt">What is the ENO Launchpad?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(1)}>
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>The ENO Launchpad is an innovative platform designed to empower the Web3 community, creators, innovators, and project developers by providing tools for staking, launching new digital assets, and minting non-fungible tokens (NFTs). The Launchpad simplifies the process of adopting, managing, and distributing digital assets while ensuring high standards of quality and security.</p>
              </li>

              <li className={activeIndex === 2 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question-alt">What are ENO Badges?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(2)}>
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>
                  ENO NFTs are unique and collectible digital assets representing
                  various experiences, products, and services within the ENO ecosystem.
                  These NFTs can be acquired, sold, and traded on the ENO Marketplace,
                  providing additional value and utility to members of the ENO community.
                </p>
              </li>

              <li className={activeIndex === 3 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question-alt">How can I mint an NFT?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(3)}>
                    <FontAwesomeIcon icon={activeIndex === 3 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. <strong>Select 'NFT Badges'.</strong><br />
                  3. Choose the badge you want to claim and click the <strong>'Mint'</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT Badges'</strong> to see all the badges you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.enotoken.io/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
              {/* Add more questions here if needed */}
            </ul>
          </div>
        </div>
      )}
      {/* Blue light desktop & mobile */}
      {showSection === "accordeon-3" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText">
              <li className={activeIndex === 0 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question">What is ENO?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(0)}>
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question">What are ENO NFT's?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(1)}>
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO NFTs are unique and collectible digital assets representing various experiences, products, and services within the ENO ecosystem. These NFTs can be acquired, sold, and traded on the ENO Marketplace, providing additional value and utility to members of the ENO community.</p>
              </li>
              <li className={activeIndex === 2 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question">How can I mint an ENO Badge?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(2)}>
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT Badge in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. Select <strong>'Badges'.</strong><br />
                  3. Choose the badge you want to claim and click the <strong>'Mint'</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT Badges'</strong> to see all the badges you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.enotoken.io/how-to-mint-an-eno-badge/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
      {/* Blue light desktop & White Mobile */}
      {showSection === "accordeon-4" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText-alt">
              <li className={activeIndex === 0 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question-alt">What is ENO?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(0)}>
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question-alt">What are ENO Badges?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(1)}>
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>ENO Badges are digital medals that demonstrate your participation on the platform. Each badge represents your attendance and commitment to various activities and events sponsored by ENO. They are unique to you and protected by blockchain technology, meaning no one can take them away or counterfeit them.</p>
              </li>
              <li className={activeIndex === 2 ? "showAnswer" : ""}>
                <div className="questionArrow">
                  <span className="question-alt">How can I mint an ENO Badge?</span>
                  <div className="arrow-container" onClick={() => toggleFAQ(2)}>
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT Badge in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. Select <strong>'Badges'.</strong><br />
                  3. Choose the badge you want to claim and click the <strong>'Mint'</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT Badges'</strong> to see all the badges you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.enotoken.io/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
      {/* White desktop & White mobile */}
      {showSection === "accordeon-5" && (
        <div className="accordionWhite">
          <div className="accordionTextWhite">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText-altWhite">
              <li className={activeIndex === 0 ? "showAnswerWhite" : ""}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">What is ENO?</span>
                  <div className="arrow-containerWhite" onClick={() => toggleFAQ(0)}>
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswerWhite" : ""}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">What is the ENO Launchpad?</span>
                  <div className="arrow-containerWhite" onClick={() => toggleFAQ(1)}>
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>The ENO Launchpad is an innovative platform designed to empower the Web3 community, creators, innovators, and project developers by providing tools for staking, launching new digital assets, and minting non-fungible tokens (NFTs). The Launchpad simplifies the process of adopting, managing, and distributing digital assets while ensuring high standards of quality and security.</p>
              </li>

              <li className={activeIndex === 2 ? "showAnswerWhite" : ""}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">What are ENO Badges?</span>
                  <div className="arrow-containerWhite" onClick={() => toggleFAQ(2)}>
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>
                  ENO NFTs are unique and collectible digital assets representing
                  various experiences, products, and services within the ENO ecosystem.
                  These NFTs can be acquired, sold, and traded on the ENO Marketplace,
                  providing additional value and utility to members of the ENO community.
                </p>
              </li>

              <li className={activeIndex === 3 ? "showAnswerWhite" : ""}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">How can I mint an NFT?</span>
                  <div className="arrow-containerWhite" onClick={() => toggleFAQ(3)}>
                    <FontAwesomeIcon icon={activeIndex === 3 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>To mint an NFT in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. <strong>Select 'NFT Badges'.</strong><br />
                  3. Choose the badge you want to claim and click the <strong>'Mint'</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT Badges'</strong> to see all the badges you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.enotoken.io/' className='links__accordionWhite'> Blog Link.</a>
                </p>
              </li>
              {/* Add more questions here if needed */}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default FAQSAccordion;
