import React from 'react';
import './NFTCard.css';

function NFTCard({ nft }) {
  const isVideo = nft.videoUrl ? nft.videoUrl.endsWith('.mp4') : false;

  const handleAddToMetaMask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC721',
            options: {
              address: nft.contractAddress,
              tokenId: nft.tokenId.toString(), 
              image: nft.videoUrl,
            },
          },
        });
      } catch (error) {
        console.error('Error adding NFT to MetaMask:', error);
      }
    } 
  };

  const handleTextClick = (event) => {
    event.stopPropagation();
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    handleAddToMetaMask();
  };

  return (
    <div className="nft-card">
      {isVideo ? (
        <video
          src={nft.videoUrl}
          alt={nft.title || "NFT"}
          className="nft-media"
          autoPlay
          loop
          muted
          playsInline
        />
      ) : (
        <img src={nft.videoUrl} alt={nft.title || "NFT"} className="nft-image" />
      )}
      <div className="nft-info">
        <div className="nft-details">
          {nft.title && <p className="nft-name">{nft.title}</p>}
          {nft.tokenId && <p className="nft-token-id"><strong className='nft-id-individual'>NFT ID: </strong>&nbsp;{nft.tokenId}</p>}
          {nft.description && <p className="nft-description" onClick={handleTextClick}>{nft.description}</p>}
        </div>
        <button className="hero__btn-mint color-1 nft__info-data" onClick={handleButtonClick}>
          Add to Wallet
        </button>
      </div>
    </div>
  );
}

export default NFTCard;
