import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import NFTCard from './NFTCard';
import erc721ABI from '../ABIs/newBadgeENOABI.json';
import { useNetworkSwitcher, chain } from '../hooks/useNetworkSwitcher';
import useMetaMaskConnector from '../hooks/useMetaMaskConnector';
import { useToast, Spinner, Flex, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import NewsNFTs from '../Templates/NewsNFTs';

import Accordion from "../Templates/Accordion";
import LatestPostsWhite from '../Templates/LatestPostWhite';
import Newsletter from "../Templates/Newsletter";
import EnoBadges from "../Templates/BadgesNewsletter";

import './MyBadges.css';
import './globalStyles.css';
import '../App.css';

const badgeContractAddresses = [
  '0xf40DDe43baA129E3cD4D73Eab2406b0e493B154b',
  '0x98fDd7a6A9C3c4c15966A71836bAC2EbD21f8480',
];

const MyNFTs = () => {
  const { currentNetwork, changeNetwork, error } = useNetworkSwitcher();
  const { isConnected, connectMetaMask, message } = useMetaMaskConnector();
  const toast = useToast();
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNFTs = useCallback(async () => {
    if (!isConnected || currentNetwork !== chain) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const badgePromises = badgeContractAddresses.map(async (contractAddress) => {
      const contract = new ethers.Contract(contractAddress, erc721ABI, signer);
      const balance = await contract.balanceOf(await signer.getAddress());
      const nftTokenIds = [];

      for (let i = 0; i < balance.toNumber(); i++) {
        const tokenId = await contract.tokenOfOwnerByIndex(await signer.getAddress(), i);
        const tokenURI = await contract.tokenURI(tokenId);
        const response = await fetch(tokenURI);
        const metadata = await response.json();

        nftTokenIds.push({
          contractAddress: contractAddress,
          tokenId: tokenId.toNumber(),
          title: metadata.name,
          videoUrl: metadata.image || '',
          description: metadata.description || '',
        });
      }

      return nftTokenIds;
    });

    const nftResults = await Promise.all(badgePromises);
    console.log(`NFT Results: `, nftResults);
    setNfts(nftResults.flat());
    setIsLoading(false);
  }, [isConnected, currentNetwork]);

  useEffect(() => {
    fetchNFTs();
  }, [fetchNFTs]);

  useEffect(() => {
    const header = document.querySelector('header');

    if (header) {
      header.style.backgroundColor = 'var(--black-eno-gray)';
    }

    return () => {
      if (header) {
        header.style.backgroundColor = ''; 
      }
    };
  }, []);

  const renderConnectMessage = () => {
    if (!isConnected && currentNetwork !== chain) {
      return 'Please connect your wallet and switch to the correct network.';
    } else if (!isConnected) {
      return 'Please connect your wallet.';
    } else if (currentNetwork !== chain) {
      return 'Please switch to the correct network.';
    }
    return '';
  };

  const renderConnectButtonLabel = () => {
    if (!isConnected) {
      return 'Connect Wallet';
    } else if (currentNetwork !== chain) {
      return 'Change Network';
    }
    return '';
  };

  const handleConnect = async () => {
    if (!isConnected) {
      await connectMetaMask();
    } else if (currentNetwork !== chain) {
      await changeNetwork();
    }
  };

  const isMetaMaskInstalled = () => {
    return typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined';
  };

  if (!isMetaMaskInstalled()) {
    return (
      <div className="install-metamask-container">
        <button as="a" href="https://metamask.io/download.html" target="_blank" colorScheme="teal" size="lg" className='connect__container-install'>
          {renderConnectButtonLabel()}
        </button>
        <p className="install-message">Please install a web3 compatible wallet to proceed.</p>
      </div>
    );
  }

  if (!isConnected || currentNetwork !== chain) {
    return (
      <div className="connect-container">
        <Button onClick={handleConnect} colorScheme="teal" size="lg" className='connect-container-btn'>
          {renderConnectButtonLabel()}
        </Button>
        <p className="connect-message">{renderConnectMessage()}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="container">
        <h2 className="hero__title">Loading...</h2>
        <Spinner size="xl" />
      </div>
    );
  }

  if (nfts.length === 0) {
    return (
      <div className="container">
        <h2 className="hero__title">No NFTs Found</h2>
        <div className="launchpad-container">
          <div className="launchpad__container-btns">
            <Link to="/launchpad">
              <button className="switch-button">Mint NFT</button>
            </Link>
            <Link to="/my-nft">
              <button className="switch-button-active">My NFTs</button>
            </Link>
          </div>
        </div>
        <p>You don't have any NFT yet. Interact with the ENO ecosystem and get your ENO NFTs.</p>
      </div>
    );
  }

  return (
    <div className="nft-purchase-container">
      <div className="container">
        <EnoBadges showSection="nfts" />
        <Flex justifyContent="center" width="100%" alignItems="center">
          <Flex alignItems="center">
            <h2 className="hero__titleWhite">My NFT Badges</h2>
          </Flex>
        </Flex>

        <div className="launchpad-container-white">
          <div className="launchpad__container-btns">
            <Link to="/launchpad">
              <button className="switch-button-white">Mint NFT</button>
            </Link>
            <Link to="/my-nft">
              <button className="switch-button-active-white">My NFTs</button>
            </Link>
          </div>
        </div>

        <div className="nft-grid-list">
          {nfts.map((nft, index) => (
            <NFTCard key={index} nft={nft} />
          ))}
        </div>

        <Accordion showSection="accordeon-5" />
        <LatestPostsWhite showSection="lastpost-1" />
        <div className="newsletter-badge__container">
          <Newsletter />
        </div>
      </div>
    </div>
  );
};

export default MyNFTs;
